import onScroll from '../utils/onScroll'

export default component => ({
  ...component,
  componentDidMount () {
    const unbind = onScroll(this.onScroll.bind(this))

    this.componentWillUnmount = () => {
      unbind()
      component.componentWillUnmount.call(this)
    }

    component.componentDidMount.call(this)
  },
})
