// import 'intersection-observer'
// import 'promise-polyfill/src/polyfill'
import './polyfills'

// import '@babel/polyfill'
import 'promise-polyfill/src/polyfill'

import { createComponent } from '@bigyouth/base'

// import 'lazysizes/plugins/rias/ls.rias.min'
// import 'lazysizes/plugins/video-embed/ls.video-embed.min'
// import 'lazysizes/plugins/bgset/ls.bgset.min'
// import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

import './assets'

import './style/styles.scss'

import './components'
import * as baseComponents from './components-base'

// Ghis stuff
// import App from './utils/project-specific/App'

// const app = new App('#app')

Object.values(baseComponents).forEach(createComponent)

// app.onReady(() => {
//   console.log('the app is ready ... ok')
// })
