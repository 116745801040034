/**
 * Send the user to the previous page in his history.
 */
export default {
  name: 'back-previous-page',

  events: [
    {
      on: 'click',
      handle (e) {
        e.preventDefault()

        history.go(-1)
      },
    },
  ],
}
