const cache = {}

const rand = () => Math.floor(Math.random() * 9999)

export default prefix => {
  if (!(prefix in cache)) {
    cache[prefix] = []
  }

  let uniq
  while (cache[prefix].includes((uniq = rand()))) {}

  cache[prefix].push(uniq)

  return `${prefix}-${uniq}`
}
