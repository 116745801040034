import customSelect from 'custom-select'
export default {
  name: 'custom-select',

  componentDidMount () {
    const customSelectEl = customSelect(this.el, {
      containerClass: 'custom-select-container',
      openerClass: 'custom-select-opener',
      panelClass: 'custom-select-panel',
      optionClass: 'custom-select-option',
      optgroupClass: 'custom-select-optgroup',
      isSelectedClass: 'is-selected',
      hasFocusClass: 'has-focus',
      isDisabledClass: 'is-disabled',
      isOpenClass: 'is-open',
    })

    const iconContainer = document.createElement('div')
    iconContainer.innerHTML = `
      <div class="custom-select-icon-wrapper">
        <svg class="custom-select-icon icon icon--gray">
          <use href="#icon-arrow-down" xlink:href="#icon-arrow-down"></use>
        </svg>
      </div>
    `

    customSelectEl[0].container.appendChild(iconContainer.firstElementChild)
  },
}
