import Swiper from 'swiper/dist/js/swiper.min.js'

export default {
  name: 'quizz',

  ui: {
    slider: '[js-quizz-slider]',
    btnPrev: '[js-slider-prev]',
    btnNext: '[js-slider-next]',
    'inputs[]': '[js-quizz-input]',
    progressText: '[js-quizz-progress-text]',
    progressBar: '[js-quizz-progress-bar]',
    wrapperResults: '[js-quizz-results]',
    wrapperResultsMax: '[js-quizz-results-max]',
    wrapperResultsMed: '[js-quizz-results-average]',
    wrapperResultsMin: '[js-quizz-results-min]',
  },

  events: [
    {
      target: 'inputs',
      on: 'click',
      handle (event) {
        this.checkAnswers(event.target)
      },
    },
    {
      target: 'btnPrev',
      on: 'click',
      handle: 'resetQuiz',
    },
  ],

  step: 1,
  validAnswers: 0,

  componentDidMount () {
    // Get all correct answers
    this.answers = this.props.answers.split(',')

    // Get Quizz type
    this.quizzType = this.props.type

    // Init slider
    this.quizSlider = new Swiper(this.ui.slider, {
      autoHeight: true,
      wrapperClass: 'quizz__inner',
      slideClass: 'quizz__slide',
      slidesPerView: 1,
      allowSlidePrev: true,
      allowSlideNext: true,
      simulateTouch: false,
    })

    this.slidesLength = this.quizSlider.slides.length

    if (this.ui.btnNext !== null) {
      this.ui.btnNext.addEventListener('click', e => {
        this.nextStep()
      })
    }
  },

  // ProgressBar behavior
  updateProgressBar () {
    this.ui.progressText.innerHTML = this.step + '/' + this.slidesLength

    this.ui.progressBar.style.width =
      (parseInt(this.step) / this.slidesLength) * 100 + '%'
  },

  resetQuiz () {
    this.ui.inputs.forEach(input => {
      input.checked = false
    })

    this.quizSlider.el.swiper.slideTo(0)

    this.ui.wrapperResults.classList.remove('active')
    this.ui.wrapperResultsMax.classList.remove('active')
    this.ui.wrapperResultsMin.classList.remove('active')

    if (this.quizzType === 'with_answer') {
      this.ui.wrapperResultsMed.classList.remove('active')
      this.ui.btnNext.classList.add('masked')

      this.ui.slider
        .querySelectorAll('.quizz-step--answer')
        .forEach(stepAnswer => {
          stepAnswer.classList.add('masked')
        })

      this.ui.slider
        .querySelectorAll('.good-answer-icon')
        .forEach(stepAnswer => {
          stepAnswer.classList.add('masked')
        })

      this.ui.slider
        .querySelectorAll('.wrong-answer-icon')
        .forEach(stepAnswer => {
          stepAnswer.classList.add('masked')
        })

      this.ui.inputs.forEach(btn => {
        btn.disabled = false
      })
    }

    this.step = 1
    this.validAnswers = 0

    this.updateProgressBar()
  },

  checkAnswers (el) {
    if (el.dataset.answer === this.answers[this.step - 1]) {
      this.validAnswers++
    }

    if (this.quizzType === 'with_answer') {
      this.showAnswer(el)
    } else {
      this.nextStep()
    }
  },

  nextStep () {
    if (this.step < this.slidesLength) {
      this.step++
      this.updateProgressBar()
      this.quizSlider.slideNext()

      if (this.quizzType === 'with_answer') {
        this.ui.btnNext.classList.add('masked')
      }
    } else {
      this.showResults()
    }
  },

  showAnswer (el) {
    let currentSlide = this.quizSlider.slides[this.quizSlider.activeIndex]
    let answerButtonSlide = currentSlide.querySelectorAll(
      '.quizz__answer__input'
    )
    let correspondingResponseWrapper = currentSlide.querySelector(
      '.quizz-step--answer'
    )
    let goodAnswerIcon = correspondingResponseWrapper.querySelector(
      '.good-answer-icon'
    )
    let wrongAnswerIcon = correspondingResponseWrapper.querySelector(
      '.wrong-answer-icon'
    )

    answerButtonSlide.forEach(btn => {
      btn.disabled = true
    })

    correspondingResponseWrapper.classList.remove('masked')

    if (el.dataset.answer === this.answers[this.step - 1]) {
      goodAnswerIcon.classList.remove('masked')
    } else {
      wrongAnswerIcon.classList.remove('masked')
    }

    this.ui.btnNext.classList.remove('masked')
  },

  showResults () {
    // last slide
    this.ui.wrapperResults.classList.add('active')

    if (this.quizzType === 'with_answer') {
      if (this.validAnswers >= 3 && this.validAnswers < 5) {
        this.ui.wrapperResultsMed.classList.add('active')
      } else if (this.validAnswers < 3) {
        this.ui.wrapperResultsMax.classList.add('active')
      } else {
        this.ui.wrapperResultsMin.classList.add('active')
      }
    } else {
      if (this.validAnswers >= 1) {
        this.ui.wrapperResultsMin.classList.add('active')
      } else {
        this.ui.wrapperResultsMax.classList.add('active')
      }
    }
  },
}
