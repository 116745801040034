import onResize from '../utils/onResize'

const SCREENS = {
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
}

const hackComponentLifecycle = component => {
  let isMounted = false
  let { min, max } = component.props.screens
  min = min ? SCREENS[min] : 0
  max = max ? SCREENS[max] : Infinity

  const { componentWillUnmount, componentDidMount } = component

  const resizeHandle = ({ width }) => {
    if (min <= width && max > width) {
      if (!isMounted) {
        isMounted = true
        if (componentDidMount) {
          componentDidMount()
        }
      }
    } else if (isMounted) {
      isMounted = false
      if (componentWillUnmount) {
        componentWillUnmount()
      }
    }
  }

  const unbind = onResize(resizeHandle)

  component.componentDidMount = () => {}
  component.componentWillUnmount = () => {
    componentWillUnmount()
    unbind()
  }
}

export default component => ({
  ...component,
  init () {
    if (this.props && typeof this.props.screens === 'object') {
      hackComponentLifecycle(this)
    }

    if ('init' in component) component.init.call(this)
  },
})
