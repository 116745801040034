const emitter = () => {
  const _listeners = {}

  return {
    on: (event, handler) => {
      if (!(event in _listeners)) {
        _listeners[event] = []
      }

      const list = _listeners[event]

      const length = list.push((...args) => new Promise(() => handler(...args)))

      return () => {
        list.splice(length - 1, 1)
      }
    },

    trigger: (event, options) =>
      event in _listeners &&
      _listeners[event].forEach(f => f({ event, ...options })),
  }
}

export default emitter()
