import emitter from './emitter'
import uniq from './uniq'

const pipes = {}

export const create = () => {
  let off
  const id = uniq('pipe')
  const event = `sigpipe-${id}`

  const self = {
    id,
    on: handle => {
      off = emitter.on(event, ({ message }) => handle(message))

      return self
    },

    write: message => {
      emitter.trigger(event, { message })

      return self
    },

    destroy: () => {
      off()
      delete pipes[id]
    },
  }

  return (pipes[id] = self)
}

export const get = id => pipes[id]
