import throttle from './throttle'
import emitter from './emitter'

const throttled = throttle(
  () =>
    emitter.trigger('scroll', {
      x: window.pageXOffset,
      y: window.pageYOffset,
    }),
  100
)

window.addEventListener('scroll', throttled, {
  passive: true,
})

/**
 * onscroll launch the callback once on definition
 */
export default handle => {
  throttled()
  return emitter.on('scroll', handle)
}
