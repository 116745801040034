import toKebabCase from './toKebabCase'

const KEY_ALIASES = {
  space: 32,
  enter: 13,
  esc: 27,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
}

export default (...keys) => event =>
  'keyCode' in event &&
  (keys.includes(toKebabCase(event.key)) ||
    keys.map(name => KEY_ALIASES[name]).includes(event.keyCode))
