import debounce from './debounce'
import emitter from './emitter'

const debounced = debounce(
  () =>
    emitter.trigger('resize', {
      width: window.innerWidth,
      height: window.innerHeight,
    }),
  100
)

window.addEventListener('resize', debounced, {
  passive: true,
})

/**
 * onResize launch the callback once on definition
 */
export default handle => {
  const off = emitter.on('resize', handle)
  debounced()

  return off
}
