const scrollManager = () => {
  let storedScrollY = 0
  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  return {
    stop: () => {
      document.body.classList.add('no-scroll-y')

      if (isIOS) {
        storedScrollY = window.pageYOffset
        document.documentElement.classList.add('no-scroll-y')
      }
    },

    restart: () => {
      document.body.classList.remove('no-scroll-y')

      if (isIOS) {
        window.scrollTo(0, storedScrollY)
        document.documentElement.classList.remove('no-scroll-y')
      }
    },
  }
}

export default scrollManager()
