import { get } from '@bigyouth/base'

import setAttributes from '../utils/setAttributes'
import removeAttributes from '../utils/removeAttributes'

const CONTENT_SELECTOR = '.app'

export default {
  name: 'cookies',

  ui: {
    btnCoockie: '[js-btn-cookie]',
  },

  events: [
    {
      target: 'btnCoockie',
      on: 'click',
      handle: 'addCookie',
    },
  ],

  classNames: {
    hidden: 'hidden',
  },

  componentDidMount () {
    this.mainContent = get(CONTENT_SELECTOR)

    this.cookieName = this.ui.btnCoockie.getAttribute('data-cookie')

    if (this.props.manage) {
      this.manageVisibility(this.props.manage)
    }
  },

  addCookie () {
    this.acceptTracking()

    if (this.props.manage) {
      this.manageVisibility(this.props.manage)
    }
  },

  acceptTracking () {
    this.setCookie(this.cookieName, true, 30)
  },

  manageVisibility (manage) {
    let cookie = this.getCookie(this.cookieName)

    if (cookie) {
      if (manage === 'hide') {
        if (this.el.classList.contains('is-hidden')) return

        this.el.classList.add('is-hidden')

        // Accessibility - Disabled the modal and enable main content on screen readers
        setAttributes({ jsSrInactive: '' })(this.el)
        removeAttributes('jsSrInactive')(this.mainContent)
      } else {
        this.el.remove()
      }
    }
  },

  getCookie (cookieName) {
    let name = cookieName + '='
    let ca = document.cookie.split(';')

    for (var i = 0; i < ca.length; i++) {
      let c = ca[i]

      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }

    return ''
  },

  setCookie (cookieName, cookieValue, cookieExpirationsDays) {
    let date = new Date()

    date.setTime(date.getTime() + cookieExpirationsDays * 24 * 60 * 60 * 1000)

    let expires = 'expires=' + date.toUTCString()

    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/'
  },

  componentWillUnmount () {},
}
