import withResponsive from '../hoc/withResponsive'

/**
 * Mock feature implementing most of the stuff on the stack.
 * Due to the 'withResponsive' HOC, foo will only mount on the requested breakpoints.
 *
 * Props:
 * shouldBar(boolean, optional): flag for toggling Bar
 * screens(object, optional): Used by withResponsive. object that can contains a 'min' and a 'max'
 * attribute. The breakpoints are specified in the withResponsive HOC.
 */
export default withResponsive({
  name: 'foo',

  ui: {
    button: '[js-foo-button]',
    'listItems[]': '[js-foo-list-item]',
  },

  events: [
    {
      on: 'hover',
      handle () {
        console.log('[FOO] hover')
      },
    },
    {
      target: 'button',
      on: 'click',
      handle () {
        console.log('[FOO] button click')
      },
    },
    {
      target: 'listItems',
      on: 'click',
      handle () {
        console.log('[FOO] click list item')
      },
    },
  ],

  componentDidMount () {
    console.log('[FOO] did mount with:')
    console.log('[FOO] this.props:', this.props)
    console.log('[FOO] this.ui:', this.ui)

    if (this.props.shouldBar) {
      this.toggleBar()
    }

    const mochArrowFunction = () => {
      console.log('[FOO] mock arrow function')
    }

    mochArrowFunction()
  },

  componentWillUnmount () {
    console.log('[FOO] will unmount')
  },

  toggleBar () {
    console.log('[FOO] toggle bar')
  },
})
