import withScroll from '../hoc/withScroll'

export default withScroll({
  name: 'header',

  ui: {
    'btnMenu[]': '[js-btn-menu]',
  },

  events: [
    {
      target: 'btnMenu',
      on: 'click',
      handle () {
        this.ui.btnMenu.forEach(this.activeMenu)
      },
    },
  ],

  state: {
    isOpen: false,
    isSiticky: false,
  },

  componentDidMount () {},

  activeMenu (btn) {
    btn.classList.toggle('active')
  },

  onScroll ({ y }) {
    this.ui.btnMenu.forEach(btn => {
      btn.classList.remove('active')
    })

    if (y > 0) {
      this.el.classList.add('is-sticky')
    } else {
      this.el.classList.remove('is-sticky')
    }
  },

  componentWillUnmount () {},
})
