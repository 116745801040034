import anime from 'animejs'
import { getAll } from '@bigyouth/base'

const INITAL_SETTERS = {
  default: () => {},
  fadeIn: target => {
    const targets = getAll('[js-scroll-animations-target]', target)
    targets.forEach(image => {
      image.style.opacity = 0
    })
  },
  translateFadeIn: target => {
    const targets = getAll('[js-scroll-animations-target]', target)
    targets.forEach(image => {
      image.style.opacity = 0
    })
  },
}

const ANIMATIONS = {
  default: () => {},
  fadeIn: target => {
    const targets = getAll('[js-scroll-animations-target]', target)

    anime({
      targets,
      opacity: 1,
      scale: [0.975, 1],
      duration: 1000,
      delay: (el, i, l) => i * 150,
      easing: 'easeOutCubic',
    })
  },
  translateFadeIn: target => {
    const targets = getAll('[js-scroll-animations-target]', target)

    anime({
      targets,
      opacity: 1,
      translateY: ['80px', 0],
      scaleY: [1.05, 1],
      duration: 1000,
      delay: (el, i, l) => i * 250,
      easing: 'easeOutCubic',
    })
  },
}

/**
 * ScrollAnimations manage all the animations launched when a target enter the screenview.
 *
 * js-scroll-animations should should be added on the parent containing all the targets.
 * js-scroll-animations-area="[ID]" should be added on all the targeted areas. [ID] is optional, if none is specified, 'default' animation is launched.
 */
export default {
  name: 'scroll-animations',

  ui: {
    'areas[]': '[js-scroll-animations-area]',
  },

  componentDidMount () {
    const observerOptions = {
      root: null,
      rootMargin: '-40% 0px -40% 0px',
      threshold: 0.0,
    }

    const observerCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const animationId =
            entry.target.getAttribute('js-scroll-animations-area') || 'default'
          const animationFunction =
            ANIMATIONS[animationId] || ANIMATIONS.default

          observer.unobserve(entry.target)
          animationFunction(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    this.ui.areas.forEach(target => {
      const animationId =
        target.getAttribute('js-scroll-animations-area') || 'default'
      const initialSetter =
        INITAL_SETTERS[animationId] || INITAL_SETTERS.default

      initialSetter(target)

      observer.observe(target)
    })
  },
}
