import saveSetAttributes from '../utils/saveSetAttributes'
import { isTabbable, getAllTabbable } from '../utils/tabbable'

/**
 * Used for accessebility issues. Disable the targeted DOM node for screen-readers.
 * You can add and remove the attribute 'js-sr-inactive' to enable or disable an element.
 */
export default {
  name: 'sr-inactive',

  resets: [],

  componentDidMount () {
    this.hiddenReset = saveSetAttributes({
      ariaHidden: true,
    })(this.el)

    if (isTabbable(this.el)) {
      this.resets.push(
        saveSetAttributes({
          tabindex: -1,
        })(this.el)
      )
    }

    getAllTabbable(this.el).forEach(tabbable => {
      this.resets.push(
        saveSetAttributes({
          tabindex: -1,
        })(tabbable)
      )
    })
  },

  componentWillUnmount () {
    this.hiddenReset()
    this.resets.forEach(reset => reset())
  },
}
