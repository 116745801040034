import onResize from '../utils/onResize'

export default {
  name: 'full-height-mobile',

  componentDidMount () {
    let that = this
    this.off = onResize(this.onResize.bind(this))

    this.onResize()
    this.calcHeight()

    window.addEventListener('orientationchange', function () {
      that.calcHeight()
    })
  },

  componentWillUnmount () {
    this.off()
  },

  onResize () {
    this.calcHeight()
  },

  calcHeight () {
    let vh = window.innerHeight * 0.01
    this.el.style.setProperty('--vh', `${vh}px`)
  },
}
