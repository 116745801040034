import { html, getAll, get } from '@bigyouth/base'
import setAttributes from '../utils/setAttributes'
import { create as createPipe } from '../utils/sigpipe'

const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
const VALID_TEL = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/ // eslint-disable-line
const VALID_DATE = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/

export const FORM_ACTIONS = {
  SUBMIT: 'FORM_SUBMIT',
  ERROR: 'FORM_ERROR',
}

/**
 * FormValidation makes sure that specified inputs are correctly filled before submitting the form.
 * By default, FormValidation trigger the native action, if a form ID is specified via its props,
 * the native action is overrided and an event is triggered with the corresponding id.
 *
 * data-validation-type="[FIELD_TYPE]" should be added on the js-form-validation-field for specific
 * tests. It can be the following: email; tel; date; checkbox; radio; length.
 * Note: for radios, add the attributes on the first one.
 *
 * !! This feature is a template and can be modified to suit the project needs.
 *
 * Props:
 * isCustomSubmit(boolean, optional): if the component should inject an id and trigger an event on submit
 */
export default {
  name: 'form-validation',

  hasError: false,

  ui: {
    'fieldWrappers[]': '[js-form-validation-field-wrapper]',
    'fields[]': '[js-form-validation-field]',
  },

  events: [
    {
      on: 'submit',
      handle: 'handleSubmit',
    },
    {
      target: 'fields',
      on: 'change',
      handle (e) {
        this.removeError(e.target)
      },
    },
  ],

  componentDidMount () {
    this.el.setAttribute('novalidate', '')

    if (this.props && this.props.isCustomSubmit) {
      this.pipe = createPipe()
      setAttributes({ dataFormId: `${this.pipe.id}` })(this.el)
    }
  },

  handleSubmit (e) {
    e.preventDefault()

    // Reset errors
    this.hasError = false
    this.removeErrors()

    this.ui.fields.forEach(field => {
      const wrapper =
        field.closest('[js-form-validation-field-wrapper]') || field.parentNode

      if (field.required) {
        if (field.value !== '') {
          this.checkField(field, wrapper)
        } else {
          this.hasError = true
          wrapper.classList.add('has-error')
          wrapper.appendChild(
            this.createErrorMessage('Veuillez remplir ce champs')
          )
        }
      } else if (
        (field.type === 'radio' && field.checked) ||
        (field.type !== 'radio' && field.value !== '')
      ) {
        this.checkField(field, wrapper)
      }
    })

    const formData = this.ui.fields.reduce((acc, field) => {
      if (field.type === 'radio') {
        const selectedRadio = get(
          `input[name="${field.name}"]:checked`,
          this.el
        )
        acc[field.name] = selectedRadio ? selectedRadio.value : ''
      } else {
        acc[field.name] = field.value
      }
      return acc
    }, {})

    if (this.hasError) {
      e.preventDefault()

      if (this.props && this.props.isCustomSubmit) {
        this.pipe.write({ action: FORM_ACTIONS.ERROR, formData })
      }
    } else if (this.props && this.props.isCustomSubmit) {
      e.preventDefault()

      this.pipe.write({ action: FORM_ACTIONS.SUBMIT, formData })
    }
  },

  checkField (field, wrapper) {
    const validationtype = field.getAttribute('data-validation-type')
    let isValid = true
    let errorLabel = 'Champs incorrect'

    switch (validationtype) {
      case 'email':
        isValid = VALID_EMAIL.test(field.value)
        if (!isValid) errorLabel = "Format d'email incorrect"
        break

      case 'tel':
        isValid = VALID_TEL.test(field.value)
        if (!isValid) errorLabel = 'Format de téléphone incorrect'
        break

      case 'date':
        isValid = VALID_DATE.test(field.value)
        if (!isValid) errorLabel = 'Format de date incorrect'
        break

      case 'radio':
        const radios = getAll(`input[name="${field.name}"]:checked`, wrapper)
        isValid = radios.length > 0
        if (!isValid) errorLabel = 'Veuillez selectionner un choix'
        break

      case 'checkbox':
        isValid = field.checked
        if (!isValid) errorLabel = 'Veuillez cocher la case'
        break

      case 'length':
        isValid = this.checkFieldLength(field)
        if (!isValid) errorLabel = 'Longueur incorrecte'
        break
    }

    if (!isValid) {
      wrapper.classList.add('has-error')
      wrapper.appendChild(this.createErrorMessage(errorLabel))
    }

    this.hasError = this.hasError || !isValid
  },

  checkFieldLength (field) {
    const minChar = parseInt(field.getAttribute('minlength')) || 0
    const maxChar = parseInt(field.getAttribute('maxlength')) || Infinity

    return field.value.length <= maxChar && field.value.length >= minChar
  },

  createErrorMessage (content) {
    const node = document.createElement('div')
    node.innerHTML = html`
      <div class="form__field-error" js-form-validation-error>${content}</div>
    `

    return node.firstElementChild
  },

  removeErrors () {
    this.ui.fields.forEach(this.removeError)
  },

  removeError (field) {
    const wrapper =
      field.closest('[js-form-validation-field-wrapper]') || field.parentNode

    if (wrapper.classList.contains('has-error')) {
      const errorLabel = get('[js-form-validation-error]', wrapper)

      wrapper.classList.remove('has-error')

      if (errorLabel) errorLabel.remove()
    }
  },
}
