export default (f, time) => {
  let timer
  let lastReceived = 0

  return (...args) => {
    const now = performance.now()
    const elapsedTime = now - lastReceived

    if (elapsedTime > time) {
      lastReceived = now

      f(...args)
    } else {
      clearTimeout(timer)
      timer = setTimeout(() => {
        lastReceived = performance.now()
        f(...args)
      }, time - elapsedTime)
    }
  }
}
