import toKebabCase from './toKebabCase'

export default attributes => el =>
  Object.keys(attributes).forEach(key => {
    const value = attributes[key]

    if (value !== null && typeof value !== 'undefined' && value !== false) {
      el.setAttribute(toKebabCase(key), value)
    } else {
      el.removeAttribute(toKebabCase(key))
    }
  })
