import { on, get } from '@bigyouth/base'

import setAttributes from '../utils/setAttributes'
import removeAttributes from '../utils/removeAttributes'
import pressedKey from '../utils/pressedKey'
import { getTabbable, getAllTabbable } from '../utils/tabbable'
import { create as createPipe } from '../utils/sigpipe'
import scrollManager from '../utils/scrollManager'

export const ACTIONS = {
  CLOSE: 'MODAL_CLOSE',
  OPEN: 'MODAL_OPEN',
}

const CONTENT_SELECTOR = '.app'

/**
 * Modal will send the corresponding dom node in the .modals layer and create a pipe for future
 * interactions.
 *
 * You can get the pipe with the id injected as an attribute on the dom node (data-pipe-id) and
 * control the modal with the actions specified above. The pipe expects an object with the following
 * attributes:
 * action(string, required): One of the values of ACTIONS.
 * emitterEl(domNode, optional): The dom node that opened the modal if there is one.
 *
 * A Modal can be controlled directly with a ModalControl or programmatically (exemple in FooForm).
 */
export default {
  name: 'modal',

  ui: {
    closeButton: '[js-modal-close]',
  },

  player: null,

  events: [
    {
      on: 'click',
      target: 'closeButton',
      handle: 'handleClose',
    },
  ],

  componentDidMount () {
    this.mainContent = get(CONTENT_SELECTOR)

    get('.modals').appendChild(this.el)
    // Create pipe for future communications
    this.pipe = createPipe()
    setAttributes({ dataPipeId: `${this.pipe.id}` })(this.el)

    this.pipe.on(({ action, emitterEl, focusTo }) => {
      this.emitterEl = emitterEl
      this.focusTo = focusTo

      switch (action) {
        case ACTIONS.OPEN:
          this.handleOpen()
          break

        case ACTIONS.CLOSE:
          this.handleClose()
          break
      }
    })

    // Accessibility - Contains tab in modal
    this.beforeBlocker = document.createElement('span')
    this.afterBlocker = this.beforeBlocker.cloneNode(true)

    this.offEscape = on('keyup', event => {
      if (pressedKey('esc')(event)) {
        this.handleClose()
      }
    })(document.body)

    this.offBeforeBlock = on('focus', () => {
      const tabbables = getAllTabbable(this.el)
      tabbables[tabbables.length - 2].focus({ preventScroll: true })
    })(this.beforeBlocker)

    this.offAfterBlock = on('focus', () => {
      getTabbable(this.el).focus({ preventScroll: true })
    })(this.afterBlocker)

    this.el.parentNode.insertBefore(this.beforeBlocker, this.el)
    this.el.appendChild(this.afterBlocker)
  },

  componentWillUnmount () {
    this.pipe.destroy()

    this.beforeBlocker.remove()
    this.afterBlocker.remove()

    this.offEscape()
    this.offBeforeBlock()
    this.offAfterBlock()

    removeAttributes('jsSrInactive')(this.mainContent)
  },

  handleOpen () {
    if (!this.el.classList.contains('is-hidden')) return

    this.el.classList.remove('is-hidden')

    // Disabled scrolling on main content
    setTimeout(scrollManager.stop, 0)

    // Accessibility - Enable the modal and disabled main content on screen readers
    removeAttributes('jsSrInactive')(this.el)
    setAttributes({ jsSrInactive: '' })(this.mainContent)

    // Accessibility - Set the focus on the cibling element in modal or modal close button
    if (this.focusTo) {
      this.focusTo.focus({ preventScroll: true })
    } else {
      this.ui.closeButton.focus({ preventScroll: true })
    }

    if (this.el.querySelector('.player-placeholder')) {
      let self = this
      let playerPlaceholder = this.el.querySelector('.player-placeholder')

      // let player
      if (!playerPlaceholder.classList.contains('video-loaded')) {
        this.ytplayer = new YT.Player(playerPlaceholder.id, {
          height: '360',
          width: '640',
          videoId: playerPlaceholder.getAttribute('data-youtube-id'),
          events: {
            onReady: self.onPlayerReady,
            // onStateChange: self.onPlayerStateChange,
          },
        })
      } else {
        this.ytplayer.playVideo()
      }
    }
  },

  handleClose () {
    if (this.el.classList.contains('is-hidden')) return

    this.el.classList.add('is-hidden')

    // Accessibility - Disabled the modal and enable main content on screen readers
    setAttributes({ jsSrInactive: '' })(this.el)
    removeAttributes('jsSrInactive')(this.mainContent)

    // Enable scrolling on main content
    scrollManager.restart()

    // Accessibility - Set the focus on the emitter that opened the modal if it exists
    if (this.emitterEl) this.emitterEl.focus({ preventScroll: true })

    if (this.el.querySelector('.player-placeholder')) {
      this.stopVideo()
    }
  },

  onPlayerReady (event) {
    this.ytplayer.a.classList.add('video-loaded')
    event.target.playVideo()
  },

  onPlayerStateChange (event) {
    let self = this
    let done = false

    if (event.data === YT.PlayerState.PLAYING && !done) {
      setTimeout(self.stopVideo, 6000)
      done = true
    }
  },

  stopVideo () {
    this.ytplayer.stopVideo()
  },
}
