import { get } from '@bigyouth/base'

import { FORM_ACTIONS } from './formValidation'
import { ACTIONS as MODAL_ACTIONS } from './modal'

import { get as getPipe } from '../utils/sigpipe'

/**
 * This is a mock form component using 'js-form-validation' field checking.
 * 'js-form-validation' generate a pipe and set a unique 'data-form-id' on the element it's added.
 * The pipe can be fetched with this id, and will receive the events emitted by 'js-form-validation'.
 */
export default {
  name: 'form-foo',

  componentDidMount () {
    const formId = this.el.getAttribute('data-form-id')

    if (formId) {
      this.pipe = getPipe(formId)

      if (this.pipe) {
        this.pipe.on(({ action, formData }) => {
          switch (action) {
            case FORM_ACTIONS.SUBMIT:
              console.info(
                `form ${this.pipe.id} has been submitted with these values:`,
                formData
              )

              const modal = get('#form-modal')
              const pipe = getPipe(modal.getAttribute('data-pipe-id'))
              pipe.write({ action: MODAL_ACTIONS.OPEN })

              break

            case FORM_ACTIONS.ERROR:
              console.warn(
                `form ${this.pipe.id} contains errors in these values:`,
                formData
              )
              break
          }
        })
      }
    }
  },
}
