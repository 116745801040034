import { getAll } from '@bigyouth/base'
import Swiper from 'swiper/dist/js/swiper.min.js'
import { detect } from 'detect-browser'

export default {
  name: 'slider',

  ui: {
    btnPrev: '[js-slider-prev]',
    btnNext: '[js-slider-next]',
  },

  componentDidMount () {
    const browser = detect()

    if (browser) {
      if (
        browser.name === 'safari' &&
        parseInt(browser.version) <= 11 &&
        browser.os === 'Mac OS'
      ) {
        console.log(browser.name)
        console.log(parseInt(browser.version))
        console.log(browser.os)
        document.body.classList.add('old-safari')
      }
    }

    let self = this
    this.allSlides = getAll('.slide', this.el)

    this.swiper = new Swiper(this.el, {
      wrapperClass: 'slider__inner',
      slideClass: 'slide',
      slidesPerView: this.props.view,
      slidesPerColumn: this.props.cols,
      spaceBetween: this.props.margs,
      slidesPerColumnFill: 'row',
      centeredSlides: this.props.centered ? this.props.centered : false,
      simulateTouch: !(this.props.cols > 1),
      // autoHeight: true, // Warning autoHeight: true is incompatible with multi slides per colummns
      navigation: {
        prevEl: this.ui.btnPrev,
        nextEl: this.ui.btnNext,
        disabledClass: 'disabled',
      },
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerColumn: 1,
          spaceBetween: 0,
        },
      },
      on: {
        slideChange: function () {
          if (window.innerWidth <= 1024) {
            // Mobile
            self.allSlides.forEach(slide => {
              slide.classList.remove('active')
            })
          }
        },
        resize: function () {
          self.allSlides.forEach(slide => {
            slide.classList.remove('active')
          })
        },
      },
    })

    var showMoreButtons = getAll('.slide__button', this.el)
    if (showMoreButtons.length) {
      showMoreButtons.forEach(button => {
        button.addEventListener('click', () => {
          if (window.innerWidth <= 1024) {
            button.closest('.slide').classList.toggle('active')
          } else {
            this.allSlides.forEach(slide => {
              slide.classList.toggle('active')
            })
          }
        })
      })
    }
  },
}
