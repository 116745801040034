import { get, getAll } from '@bigyouth/base'

/**
 * Scroll to the targeted element passed by props.
 *
 * Props:
 * target(string, required): selector of the targetted element.
 * targetIndex(int, optional): index of the target you want to scroll to.
 *   A getAll with this.props.target will be done if targetIndex is specified.
 *   Then the [targetIndex] of the targets will be selected.
 */
export default {
  name: 'scroll-to',

  events: [
    {
      on: 'click',
      handle () {
        if (this.target) {
          this.target.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      },
    },
  ],

  componentDidMount () {
    if (this.props.targetIndex) {
      this.target = getAll(this.props.target)[this.props.targetIndex]
    } else {
      this.target = get(this.props.target)
    }
  },
}
