/**
 * Inject SVG code in the DOM using the URL passed through the props.
 *
 * Props:
 * url(string, required): URL of the SVG file
 */
export default {
  name: 'inline-svg',

  componentDidMount () {
    if (this.props.url) {
      const ajax = new XMLHttpRequest()

      ajax.open('GET', this.props.url, true)
      ajax.send()
      ajax.onload = () => {
        this.el.innerHTML = ajax.responseText
      }
    }
  },
}
